import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2023";
  const teamNames = teamsData.map((team) => team.seasons[season].name);
  const teamColors = teamsData.map((team) => team.color);
  const trades = tradesData[0][season].trades;

  useEffect(() => {
    const teams = teamsData;    
    
    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].pointsFor),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossible),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPercPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => (
            (team.seasons[season].pointsFor / team.seasons[season].pointsPossible) * 100).toFixed(2)
          ),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsAgainst),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].avgScore),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartTrades"), {
        type: 'horizontalBar',
        data: {
          datasets: [{
              data: teams.map((team) => team.seasons[season].tradeCount),
              backgroundColor: teamColors,
          }],
  
          labels: teamNames
        },
        options: {
          legend: {
            display: false
          }
        }
      });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '181.60',
            '168.66',
            '163.10',
            '161.12',
            '157.42',
            '154.54',
            '152.96',
            '151.14',
            '148.74',
            '146.88'
          ],
          backgroundColor: [
            teamColors[1],
            teamColors[2],
            teamColors[8],
            teamColors[6],
            teamColors[3],
            teamColors[8],
            teamColors[2],
            teamColors[9],
            teamColors[5],
            teamColors[9]
          ],
        }],
        labels: [
          teamNames[1] + ' - Week 3',
          teamNames[2] + ' - Week 12',
          teamNames[8] + ' - Week 3',
          teamNames[6] + ' - Week 15',
          teamNames[3] + ' - Week 16',
          teamNames[8] + ' - Week 2',
          teamNames[2] + ' - Week 5',
          teamNames[9] + ' - Week 12',
          teamNames[5] + ' - Week 14',
          teamNames[9] + ' - Week 13'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].aboveAvgPerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartWeeklyPlaces"), {
      type: 'line',
      data: {
        labels: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Week 4',
          'Week 5',
          'Week 6',
          'Week 7',
          'Week 8',
          'Week 9',
          'Week 10',
          'Week 11',
          'Week 12',
          'Week 13',
          'Week 14',
          'Week 15',
          'Week 16',
          'Week 17'
        ],
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.seasons[season].name,
              data: team.seasons[season].weeklyPlaces,
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top',
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartOriginalTeam"), {
      type: 'polarArea',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].originalTeamPerc),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teams.map((team) => team.seasons[season].tier1_QB.length),
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teams.map((team) => team.seasons[season].tier1_RB.length),
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teams.map((team) => team.seasons[season].tier1_WR.length),
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teams.map((team) => team.seasons[season].tier1_TE.length),
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teams.map((team) => team.seasons[season].tier1_DST.length),
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>{season} Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>{season} Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Mahomebois (9-6)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Charm City Trading Co. (9-6)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> Narcoleptic Skydivers (9-6)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> Take a poo leave a poo (11-4)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> Human Poop (8-7)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Chodemissioner (8-7)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> jhoffman (7-8)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> Stroud's Chodes (6-9)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> Tua Tuggin'onthelow'a (5-10)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> Piss Jugman (3-12)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>P. Mahomes (KC)</td>
                      <td>13.00</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>D. Swift (PHI)</td>
                      <td>7.10</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>A. Kamara (NO)</td>
                      <td>5.90</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>J. Reed (GB)</td>
                      <td>23.90</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>A. Brown (PHI)</td>
                      <td>7.30</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>D. Njoku (CLE)</td>
                      <td>13.40</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Z. Flowers (BAL)</td>
                      <td>18.10</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>J. Mixon (CIN)</td>
                      <td>16.70</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>Browns</td>
                      <td>14.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>119.40</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Charm City Trading Co.</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>Piss Jugman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Take a poo leave a poo</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>jhoffman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>Human Poop</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Piss Jugman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="increasing chart">📈</span>Most Improved</h3>
                <div className="desc">(biggest increase from last season in points for)</div>
                <h4>Narcoleptic Skydivers (+409.54)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="decreasing chart">📉</span>Least Improved </h3>
                <div className="desc">(biggest decrease from last season in points for)</div>
                <h4>Take a poo leave a poo (-398.56)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="check mark">✔️</span>Most Consistent Scorer</h3>
                <h4>Charm City Trading Co.</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="cross mark">❌</span>Least Consistent Scorer </h3>
                <h4>Stroud's Chodes</h4>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Finishes</h2>
            <canvas id="chartWeeklyPlaces"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Percentage of Startup Draft Team</h2>
            <div className="chart-desc">(% of original team remaining)</div>
            <canvas id="chartOriginalTeam"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>J. Allen</td>
                      <td>417.6</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Hurts</td>
                      <td>393.2</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Prescott</td>
                      <td>377.7</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>L. Jackson</td>
                      <td>368.2</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Love</td>
                      <td>352.5</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>B. Purdy</td>
                      <td>351.6</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>P. Mahomes</td>
                      <td>329.2</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Goff</td>
                      <td>318.3</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>B. Mayfield</td>
                      <td>315.9</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>T. Tagovailoa</td>
                      <td>305.7</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>R. Wilson</td>
                      <td>298.9</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>M. Stafford</td>
                      <td>291.1</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>C. McCaffrey</td>
                      <td>354.8</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>R. Mostert</td>
                      <td>251.2</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>T. Etienne</td>
                      <td>242.2</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>K. Williams</td>
                      <td>236</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>B. Hall</td>
                      <td>222.5</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>R. White</td>
                      <td>221.6</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Mixon</td>
                      <td>216.8</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>D. Henry</td>
                      <td>215.4</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>J. Gibbs</td>
                      <td>205.1</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Cook</td>
                      <td>200</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>A. Kamara</td>
                      <td>195.5</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>I. Pacheco</td>
                      <td>190.9</td>
                      <td>Chodemissioner</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>C. Lamb</td>
                      <td>303.7</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Hill</td>
                      <td>298.2</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>A. St. Brown</td>
                      <td>246.5</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M. Evans</td>
                      <td>239.3</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>A. Brown</td>
                      <td>236.2</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>P. Nacua</td>
                      <td>232.9</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>D. Moore</td>
                      <td>229.1</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>K. Allen</td>
                      <td>224.9</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>D. Samuel</td>
                      <td>208.5</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Chase</td>
                      <td>207.8</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>B. Aiyuk</td>
                      <td>206.7</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>S. Diggs</td>
                      <td>206.1</td>
                      <td>Chodemissioner</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>S. LaPorta</td>
                      <td>184.9</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Kelce</td>
                      <td>171.9</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>G. Kittle</td>
                      <td>170.7</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>T. Hockenson</td>
                      <td>170.5</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>D. Njoku</td>
                      <td>158.7</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>E. Engram</td>
                      <td>151.4</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>C. Kmet</td>
                      <td>138</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Ferguson</td>
                      <td>131.7</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>T. McBride</td>
                      <td>129.1</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>T. Hill</td>
                      <td>118.1</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>D. Schultz</td>
                      <td>113.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>M. Andrews</td>
                      <td>111.9</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Ravens</td>
                      <td>174</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Browns</td>
                      <td>172</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Cowboys</td>
                      <td>167</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Bills</td>
                      <td>158</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Jets</td>
                      <td>154</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Dolphins</td>
                      <td>151</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Chiefs</td>
                      <td>142</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>49ers</td>
                      <td>141</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Raiders</td>
                      <td>138</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Vikings</td>
                      <td>135</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Colts</td>
                      <td>135</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Steelers</td>
                      <td>133</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>Charm City Trading Co. - 128.38</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                  <td>Piss Jugman - 22.74 drop in score</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Stroud's Chodes - C. Stroud (51.8 yds)</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>Mahomebois - A. Brown (9)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>Chodemissioner - D. Smith (1.10 pts)</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>Take a poo leave a poo - 0.40 pts</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Lockdown</td>
                  <td>Team with the least amount of yards given up on defense</td>
                  <td>Take a poo leave a poo - Eagles (249 yds)</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Chodemissioner - D. Smith and Tua Tuggin'onthelow'a - G. Kittle (19.4 pts)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>Doing the Most</td>
                  <td>Team with the WR or TE with the highest yards after catch per reception (YAC/R)</td>
                  <td>Narcoleptic Skydivers - J. Smith (21.1 yds)</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Human Poop - J. Jacobs and Charm City Trading Co. - D. Montgomery (116 yds)</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>Chodemissioner - 111.88 pts</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>To Completion</td>
                  <td>Team with the QB with the highest completion percentage</td>
                  <td>Mahomebois - P. Mahomes (79.4%)</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>Narcoleptic Skydivers - 9 TDs</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Efficient Zoomies</td>
                  <td>Team with the RB with the highest average rushing yards</td>
                  <td>Narcoleptic Skydivers - C. McCaffrey (9.1 YPC)</td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most points for on the season that didn't make the playoffs</td>
                  <td>Human Poop - 1717.04 pts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>

            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades.map((data,id)=>{
                return <Trade key={id} data={data} teamNames={teamNames} />
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;